.request-pricing-btns-container-sm-to-md {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0px 12px;
  height: 96px;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 5;
  transition: all 0.1s ease-in-out;
  flex-direction: row-reverse;
}
.request-pricing-btn {
  width: 100%;
  height: 48px;
  margin-top: 30px;
}
.request-pricing-call-btn {
  padding-top: 12px;
  height: 48px;
  width: 48px;
  margin-top: 30px;
}
