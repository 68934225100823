.choose-path-container {
  margin: 0px auto;
  margin-bottom: 80px;
  .choose-path-top-section-container {
    padding: 48px 20px;
    padding-bottom: 200px;
    // background-color: #1e2128ff;
    background: radial-gradient(
        90% 90% at 80% 50%,
        rgba(21, 31, 42, 0.4) 0%,
        #04080c 100%
      ),
      url(https://images.unsplash.com/photo-1629046133174-b5c0d944640b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000);
    border-radius: 4px;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    align-items: center;
    justify-content: center;

    :first-child {
      max-width: 1275px;
    }
  }

  .choose-path-options-row-container {
    background-color: white;
    margin: -160px auto 0px auto;
    width: 100%;
    border-radius: 0px;
    padding: 32px 20px 20px 20px;
    max-width: 1280px;

    .choose-path-option-col {
      width: 100%;
      height: 100%;
    }

    .choose-path-option {
      background: #ffffff99;
      border-radius: 8px;
      border: 1px solid #dee1e6ff;
      width: 100%;
      padding: 12px 16px 24px 16px;

      .choose-path-option-header {
        background: #f8f5faff;
        border-radius: 8px;
        padding: 12px 24px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 24px;

        img {
          display: none;
        }

        .choose-path-option-title {
          font-size: 28px;
          font-weight: 500;
          line-height: 36px;
          color: #171a1fff;
        }
      }
      .choose-path-option-2-header {
        // background: #171a1fff;
        background: rgba(0, 0, 0, 0)
          url(https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/abstract-textured-backgound-2.jpg?v=2023-05-01T17:22:07.340Z)
          0% 0% / cover repeat scroll padding-box;
        &:first-child {
          span,
          h1 {
            color: white;
          }
          :last-child {
            color: #a8adb7ff;
          }
        }
      }

      .choose-path-option-btn {
        width: 100%;
        height: 52px;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        // color: #5a3970ff;
        // background: #f8f5faff;
        color: rgba(0, 0, 0, 0.9);
        background: #ffe499;
        opacity: 1;
        border: none;
        border-radius: 26px;
        margin-top: 48px;

        /* Hover */
        &:hover {
          // background: #e5daecff;
          background: rgba(250, 197, 50, 1);
        }
        /* Pressed */
        &:hover:active {
          background: #d2bedfff;
        }
        /* Disabled */
        &:disabled {
          opacity: 0.4;
        }
      }

      .choose-path-option-btn-2 {
        color: #ffffffff;
        background: #5a3970ff;
        &:hover {
          background: #4b2f5eff;
        }
        &:hover:active {
          background: #362243ff;
        }
      }
    }
  }

  .not-sure-text {
    margin-bottom: 16px;
    font-weight: 700;
    line-height: unset;
    color: #171a1fff;
    margin-top: 24px;
    font-size: 22px;
  }
  .bell-icon {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .choose-path-container {
    .choose-path-top-section-container {
      padding-top: 120px;
    }
    .choose-path-options-row-container {
      padding: 36px;

      width: 90%;
      border-radius: 20px;

      .choose-path-option-col {
        width: 100%;
      }
      .choose-path-option {
        .choose-path-option-header {
          .choose-path-option-title {
            font-size: 38px;
            line-height: 54px;
          }
          img {
            display: block;
          }
        }
      }
    }
    .not-sure-text {
      font-size: 42px;
      line-height: 48px;
    }
    .bell-icon {
      display: block;
    }
  }
}
