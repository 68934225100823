.mb-1 {
  margin-bottom: 8px;
}
.mb-2 {
  margin-bottom: 16px;
}
.mb-3 {
  margin-bottom: 24px;
}
.mb-4 {
  margin-bottom: 36px;
}

/* Make sure your images fill their container (optional) */
.grid-image {
  width: 100%;
  display: block;
  margin-bottom: 16px;
  border-radius: 12px;
  cursor: pointer;
}

.masonry-grid {
  display: flex;
  width: auto;
  max-width: 1200px;

  gap: 16px;
  margin: 0px auto;
  padding: 0px 16px;
  padding-bottom: 8px; /* .grid-image has 16px margin-bottom => 16 + 8 = 24px */
}

.masonry-grid_column {
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.home-gallery-grid-container {
  display: grid;
  grid-auto-columns: 1fr;
  gap: 4px;
  overflow: hidden;
  grid-template-areas:
    "storefrontGalleryImage1"
    "storefrontGalleryImage2"
    "storefrontGalleryImage3"
    "storefrontGalleryImage4";

  img {
    object-fit: cover; /* Use 'cover' to make sure the image covers the entire container */
    width: 100%; /* Set the width to 100% to ensure it takes up the available space */
    height: 100%; /* Set the height to 100% to maintain aspect ratio */
    max-width: 100%; /* Ensure the image doesn't exceed its original size */
    max-height: 100%;
  }

  .gallery-image-div1,
  .gallery-image-div2,
  .gallery-image-div3 {
    display: none;
  }

  :nth-child(1) {
    grid-area: storefrontGalleryImage1;
  }
  :nth-child(2) {
    grid-area: storefrontGalleryImage2;
  }
  :nth-child(3) {
    grid-area: storefrontGalleryImage3;
  }
  :nth-child(4) {
    grid-area: storefrontGalleryImage4;
  }
}

@media screen and (min-width: 768px) {
  // Styles for medium screens and larger go here
  .home-gallery-grid-container {
    grid-template-areas:
      "storefrontGalleryImage1 storefrontGalleryImage1 storefrontGalleryImage2 storefrontGalleryImage3"
      "storefrontGalleryImage1 storefrontGalleryImage1 storefrontGalleryImage4 storefrontGalleryImage4";
    // grid-template-columns: 440px 220px 220px;
    // grid-template-rows: 234px 234px;
    grid-template-rows: 184px 184px;

    .gallery-image-div1,
    .gallery-image-div2,
    .gallery-image-div3 {
      display: block;
    }

    :nth-child(1) {
      img {
        border-radius: 8px 0px 0px 8px;
      }
    }
    :nth-child(3) {
      img {
        border-radius: 0px 8px 0px 0px;
      }
    }
    :nth-child(4) {
      img {
        border-radius: 0px 0px 8px 0px;
      }
    }
  }
}
@media screen and (min-width: 1100px) {
  .home-gallery-grid-container {
    grid-template-rows: 234px 234px;
  }
}
