.storefrontGalleryMosaicFullscreen__list__container {
  // height: 100vh;
  height: calc(100dvh - 142px);
  overflow: auto;
}

.store-front-media-container {
  // height: calc(100dvh - 170px);
  // height: calc(100vh - 170px);
  // height: calc(100dvh - 142px);
  // height: calc(100vh - 142px);
  height: calc(100dvh - 390px);
  height: calc(100vh - 390px);
}
// calc(100vh - 390px)

.storefrontGalleryMosaicFullscreen__list {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.25rem;
  width: 100%;

  img {
    object-fit: cover; /* Use 'cover' to make sure the image covers the entire container */
    width: 100%; /* Set the width to 100% to ensure it takes up the available space */
    height: 100%; /* Set the height to 100% to maintain aspect ratio */
    max-width: 100%; /* Ensure the image doesn't exceed its original size */
    max-height: 100%;
  }
  .storefrontGalleryMosaicFullscreen__item {
    width: 100%;
    height: 150px;
    cursor: pointer;

    &:nth-child(5n + 1) {
      grid-column: 1/-1;
      height: 250px;
    }

    &:nth-child(5n + 2) {
      grid-column: 1/-2;
    }
    &:nth-child(5n + 5) {
      grid-column: 2/-1;
    }
  }
}

@media screen and (min-width: 768px) {
  .storefrontGalleryMosaicFullscreen__list {
    .storefrontGalleryMosaicFullscreen__item {
      &:nth-child(5n + 1) {
        grid-column: 1/-1;
        height: 400px;
      }
      &:nth-child(5n + 2),
      &:nth-child(5n + 3),
      &:nth-child(5n + 4),
      &:nth-child(5n + 5) {
        height: 300px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .storefrontGalleryMosaicFullscreen__list {
    .storefrontGalleryMosaicFullscreen__item {
      &:nth-child(5n + 1) {
        height: 500px;
      }
      &:nth-child(5n + 2),
      &:nth-child(5n + 3),
      &:nth-child(5n + 4),
      &:nth-child(5n + 5) {
        height: 400px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .store-front-media-container {
    // height: calc(100dvh - 170px);
    // height: calc(100vh - 170px);
    height: calc(100dvh - 142px);
    height: calc(100vh - 142px);
  }

  .storefrontGalleryMosaicFullscreen__list {
    .storefrontGalleryMosaicFullscreen__item {
      &:nth-child(5n + 1) {
        height: 500px;
      }
      &:nth-child(5n + 2),
      &:nth-child(5n + 3),
      &:nth-child(5n + 4),
      &:nth-child(5n + 5) {
        height: 400px;
      }
    }
  }
}

@media screen and (min-width: 1950px) {
  .storefrontGalleryMosaicFullscreen__list {
    .storefrontGalleryMosaicFullscreen__item {
      &:nth-child(5n + 1),
      &:nth-child(5n + 2),
      &:nth-child(5n + 3),
      &:nth-child(5n + 4),
      &:nth-child(5n + 5) {
        height: 700px;
        grid-column: auto;
      }
    }
  }
}

.swiper-styles {
  .swiper-button-next,
  .swiper-button-prev {
    color: #6c23a0;
  }
  .swiper-pagination.swiper-pagination-bullets {
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: #6c23a0;
    }
  }
}
